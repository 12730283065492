<template>
  <section>
    <v-col cols="12" md="12">
      <p class="text-h6 secondary--text mb-0">
        {{
          $route.params.idProveedor
            ? `Rupes / ${provider.nombre}`
            : "Perfil del proveedor"
        }}
        <v-tooltip v-if="provider?.id_estado_verificacion === 1" right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" color="yellow accent-4" size="30"
              >mdi-check-decagram</v-icon
            >
          </template>
          <span>Perfil verificado</span>
        </v-tooltip>
        <v-tooltip v-else-if="perfilCompleto" right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" color="success" size="30"
              >mdi-check-decagram</v-icon
            >
          </template>
          <span>Perfil completo</span>
        </v-tooltip>
      </p>
      <p
        v-if="perfilCompleto && $route.params.idProveedor == null"
        class="success--text"
      >
        Información completa
      </p>
    </v-col>

    <v-col cols="12" class="mb-6 pb-8" v-if="!perfilCompleto">
      <h3>Tu perfil está incompleto</h3>
      <p>
        Para poder aplicar a las ofertas debes completar los campos en las
        opciones de: OBS, giro empresarial, documentación, accionistas y
        contacto
      </p>
      <v-progress-linear
        color="#FFEAA7"
        :value="progresoPerfil"
        readonly
        height="25"
      >
        <strong>{{ Math.ceil(progresoPerfil) }} %</strong>
      </v-progress-linear>
    </v-col>

    <v-row>
      <v-col
        cols="12"
        :md="provider?.id_estado_verificacion == 2 ? 3 : 6"
        sm="12"
        class="d-flex justify-center"
      >
        <!-- Logo del proveedor -->
        <v-row>
          <v-col class="d-flex justify-center">
            <v-img
              v-if="file != null || providerLogo"
              max-height="300"
              max-width="300"
              :src="showImage"
              :lazy-src="showImage"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-icon v-else color="primary" size="300px">
              mdi-image-outline
            </v-icon>
            <div v-if="perfilCompleto" class="d-flex mx-4 align-end">
              <v-icon color="success">
                mdi-checkbox-marked-circle-outline
              </v-icon>
            </div>
          </v-col>
          <v-col cols="12" md="12" class="d-flex justify-center">
            <v-file-input
              hint="Archivo JPG o PNG"
              v-model="file"
              class="image-input"
              accept="image/*"
              enctype="multipart/form-data"
              label="Clic aquí para cargar el logo de su empresa"
              @change="cargarLogo($event)"
            />
          </v-col>
        </v-row>
      </v-col>

      <!-- Información del proveedor -->

      <v-col
        cols="12"
        :md="provider?.id_estado_verificacion == 2 ? 4 : 6"
        sm="12"
        class="px-10"
      >
        <v-row>
          <v-col class="espace" cols="12" md="6" sm="6">
            <p class="text-title">Nombre del proveedor</p>
            <p v-if="!editFields" class="text-info">{{ provider.nombre }}</p>
            <v-text-field
              dense
              v-else
              v-model="editableFields.nombre"
              @input="$v.editableFields.nombre.$touch()"
              :error-messages="NombreProveedorErrors"
            />
          </v-col>

          <v-col class="espace" cols="12" md="6" sm="6">
            <p class="text-title">Nombre comercial</p>
            <p v-if="!editFields" class="text-info">
              {{ provider.nombre_comercial }}
            </p>
            <v-text-field
              dense
              v-else
              v-model="editableFields.nombre_comercial"
              @input="$v.editableFields.nombre_comercial.$touch()"
              :error-messages="NombreComercialErrors"
            />
          </v-col>

          <v-col class="espace" cols="12" md="6" sm="6">
            <p class="text-title">Tipo de persona</p>
            <p class="text-info">{{ provider.tipo_contribuyente }}</p>
          </v-col>

          <v-col class="espace" cols="12" md="6" sm="6">
            <p class="text-title">Género</p>
            <p class="text-info">{{ provider.genero }}</p>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <p class="text-title">
              {{ getDocumentName(provider.documentos) }}
            </p>
            <p class="text-info">
              {{ getDocumentNumber(provider.documentos) }}
            </p>
          </v-col>

          <v-col class="espace" cols="12" md="6" sm="6">
            <p class="text-title">Sitio web</p>
            <p v-if="!editFields" class="text-info">
              {{ provider.sitio_web ? provider.sitio_web : "No posee" }}
            </p>
            <v-text-field
              @input="$v.editableFields.sitio_web.$touch()"
              :error-messages="SitioWebErrors"
              dense
              v-else
              v-model="editableFields.sitio_web"
            />
          </v-col>

          <v-col class="espace" cols="12" md="6" sm="6">
            <div v-if="!editFields">
              <p class="text-title">Clasificación de la empresa</p>
              <p class="text-info">{{ provider.clasificacion_empresa }}</p>
            </div>
            <div v-else>
              <v-autocomplete
                label="Clasificación empresarial *"
                outlined
                color="secondary"
                :items="clasificacion_empresarial"
                item-text="clasificacion"
                item-value="id"
                v-model="editableFields.id_clasificacion_empresa"
                :error-messages="clasificacionEmpresaErrors"
              />
            </div>
          </v-col>

          <v-col class="espace" cols="12" md="6" sm="6">
            <P class="text-title">Teléfono</P>
            <p v-if="!editFields" class="text-info">
              {{ `${provider.area + " " + provider.telefono}` }}
            </p>
            <v-row v-else>
              <v-col cols="3">
                <v-text-field readonly dense v-model="editableFields.area" />
              </v-col>
              <v-col cols="9">
                <v-text-field
                  @input="$v.editableFields.telefono.$touch()"
                  :error-messages="phoneErrors"
                  dense
                  v-mask="mask"
                  v-model="editableFields.telefono"
                  :maxLength="20"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col
            v-if="provider.id_identificacion_tributaria === 3"
            class="espace"
            cols="12"
          >
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <div class="mb-4">
                  <p class="text-title" style="font-size: 0.9em">
                    ¿Está constituida como una sociedad por acciones
                    simplificada (SAS)?
                  </p>
                  <v-switch
                    v-model="editableFields.sas"
                    hide-details
                    :disabled="!editFields"
                    color="secondary"
                    class="mt-0"
                    :label="editableFields.sas ? 'Si' : 'No'"
                    :truthy-value="true"
                    :false-value="false"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="espace" cols="12" md="6" sm="6">
            <p class="text-title">Dirección</p>
            <p v-if="!editFields" class="text-info">
              {{ getAddress(provider.direccion) }}
            </p>
            <template v-else>
              <div class="mt-6 mb-4">
                <v-autocomplete
                  :error-messages="paisErrors"
                  v-model="editableFields.id_pais"
                  dense
                  label="País"
                  :items="paises"
                  item-value="id"
                  item-text="nombre"
                  class="mr-4"
                  @change="clearAddressFields()"
                />
              </div>
              <div class="mb-6">
                <v-autocomplete
                  v-if="editableFields?.id_pais == 68"
                  v-model="editableFields.id_departamento"
                  dense
                  :items="departamentos"
                  label="Departamento"
                  class="mr-4"
                  item-value="id"
                  item-text="nombre"
                  @blur="$v.editableFields.id_departamento.$touch()"
                  :error-messages="departamentoErrors"
                  @change="getMunicipios()"
                />
              </div>
              <div class="mb-6">
                <v-autocomplete
                  v-if="editableFields?.id_pais == 68"
                  v-model="editableFields.id_municipio"
                  dense
                  :items="municipios"
                  label="Municipio"
                  class="mr-4"
                  item-value="id"
                  @blur="$v.editableFields.id_municipio.$touch()"
                  :error-messages="municipioErrors"
                  item-text="nombre"
                />
              </div>
              <div class="">
                <v-textarea
                  v-model="editableFields.direccion"
                  dense
                  rows="2"
                  label="Dirección"
                  class="mr-4"
                  @input="$v.editableFields.direccion.$touch()"
                  :error-messages="addressErrors"
                />
              </div>
            </template>
          </v-col>

          <v-col class="espace" cols="12" md="6" sm="6">
            <p class="text-title">Correo electrónico</p>
            <p v-if="!editFields" class="text-info">{{ provider.email }}</p>
            <v-text-field
              v-else
              @input="$v.editableFields.email.$touch()"
              :error-messages="emailErrors"
              dense
              v-model="editableFields.email"
              :maxLength="50"
            />
          </v-col>
        </v-row>
        <br />
        <br />

        <div class="d-flex align-center">
          <span class="grayMinsal--text"
            >Clic aquí para {{ editFields ? "guardar" : "editar" }} su
            información</span
          >
          <v-btn
            @click="editFields = true"
            icon
            v-if="!editFields"
            class="mx-3"
            color="secondary"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>

          <template v-else>
            <v-btn
              @click="editProfileData()"
              icon
              class="mx-3"
              color="secondary"
            >
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
            <v-btn @click="editFields = false" icon color="secondary">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </template>
        </div>
      </v-col>
      <v-col
        v-if="provider?.id_estado_verificacion == 2"
        cols="12"
        md="4"
        sm="12"
      >
        <v-card>
          <v-container>
            <div class="d-flex align-center text-subtitle-1 secondary--text">
              <v-icon color="yellow" class="mr-2"
                >mdi-message-alert-outline</v-icon
              >
              Observaciones
            </div>
            <div class="my-2">
              <p class="grayMinsal--text">
                Estimado usuario, debes de tomar en cuenta y corregir los
                siguientes apartados:
              </p>
            </div>
            <div class="d-flex flex-column">
              <p v-for="(obs, index) in provider.observaciones_verificacion">
                {{ index + 1 }}. {{ replaceFun(obs.observacion) }}
              </p>
            </div>
            <div class="d-flex justify-center my-2">
              <v-btn @click="modal_observacion = true" dark color="secondary">
                Resolver observaciones
              </v-btn>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <!-- Opciones del menu de usuario -->
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="12" sm="12" class="d-flex justify-start">
        <p class="text-h6 secondary--text mx-3">Documentación y servicios</p>
      </v-col>

      <PerfilItem
        icon="mdi-archive-plus-outline"
        label="OBS"
        route="agregar-lista"
        :params="{ type: 'OBS' }"
      >
      </PerfilItem>
      <PerfilItem
        icon="mdi-briefcase-check-outline"
        label="Giro empresarial"
        route="agregar-lista"
        :params="{ type: 'GIRO' }"
      ></PerfilItem>
      <PerfilItem
        icon="mdi-text-box-plus-outline"
        label="Documentación"
        route="documentos"
      ></PerfilItem>
      <template
        v-if="
          !(
            [1].includes(
              provider?.identificacion_tributaria?.id_tipo_contribuyente
            ) && [1, 2].includes(provider?.id_clasificacion_empresa)
          )
        "
      >
        <PerfilItem
          icon="mdi-account-group-outline"
          label="Accionistas"
          route="accionistas"
        ></PerfilItem>
      </template>

      <PerfilItem
        icon="mdi-phone-outline"
        label="Contactos"
        route="contactos"
      ></PerfilItem>
      <template
        v-if="
          haveRole('ROLE_PROVEEDOR') &&
          !haveRoles([
            'ROLE_DIRECCION_COMPRAS',
            'ROLE_TECNICO_DIRECCION_COMPRAS',
          ])
        "
      >
        <PerfilItem
          icon="mdi-alert-outline"
          label="Sanciones"
          route="sanciones-proveedor"
          :params="{ id_proveedor: provider.id_usuario }"
        />
      </template>
      <PerfilItem
        v-if="haveRole('ROLE_GESTION_EMPLEADOS_PROVEEDOR') && !haveRole('ROLE_PROVEEDOR_EMPLEADO')"
        icon="mdi-account-multiple-check"
        label="Perfiles"
        route="empleadosProveedor"
        :params="{
          ...(!haveRole('ROLE_PROVEEDOR') && {
            id_proveedor: provider.id_usuario,
          }),
        }"
      ></PerfilItem>
    </v-row>

    <confirmation-dialog-component
      :show="modal_observacion"
      title="¿Desea marcar como resueltas las observaciones?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="modal_observacion = false"
      @confirm="solveObservations()"
    />
  </section>
</template>

<script>
import PerfilItem from "./components/PerfilItemComponent.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import { Observable } from "@/utils/observable";
import {
  helpers,
  requiredIf,
  maxLength,
  required,
  email,
} from "vuelidate/lib/validators";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";

const alpha = helpers.regex("alpha", /^[a-zA-ZÀ-ÿ0-9-_.,#\u00f1\u00d1 ]*$/);
const address = helpers.regex("address", /^[0-9A-Za-zñÑáéíóúÁÉÍÓÚ,.#_\-' ]*$/);
function phoneValid(value) {
  if (value) {
    if (this.editableFields.area == "+503") {
      if (
        value.charAt(0) == "2" ||
        value.charAt(0) == "6" ||
        value.charAt(0) == "7"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      const regexAlphaNum = /^[0-9-]+$/;
      return regexAlphaNum.test(value);
    }
  } else {
    return true;
  }
}
const url = helpers.regex(
  "url",
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
);
export default {
  name: "perfilProveedorView",
  components: {
    PerfilItem,
    ConfirmationDialogComponent,
  },
  data: () => ({
    modal_observacion: false,
    file: null,
    logo: null,
    proveedor: [],
    editFields: false,
    editableFields: {
      nombre: null,
      nombre_comercial: null,
      sitio_web: null,
      telefono: null,
      area: null,
      id_pais: null,
      id_departamento: null,
      id_municipio: null,
      direccion: null,
      email: null,
      id_clasificacion_empresa: null,
      sas: false,
    },
    paises: [],
    departamentos: [],
    municipios: [],
    progresoPerfil: 0,
    perfilCompleto: true,
    mask: null,
  }),
  validations: {
    editableFields: {
      nombre: { required, maxLength: maxLength(100), alpha },
      nombre_comercial: { required, maxLength: maxLength(100), alpha },
      sitio_web: { url },
      direccion: { required, address, maxLength: maxLength(100) },
      id_pais: { required },
      telefono: { phoneValid },
      id_departamento: {
        required: requiredIf(function () {
          return this.editableFields?.id_pais == 68 ? true : false;
        }),
      },
      id_municipio: {
        required: requiredIf(function () {
          return this.editableFields?.id_pais == 68 ? true : false;
        }),
      },
      email: { required, email },
      id_clasificacion_empresa: { required },
    },
  },
  computed: {
    ...mapState("proveedores", ["provider", "providerLogo"]),
    ...mapState("registroProveedor", ["clasificacion_empresarial"]),
    showImage() {
      if (this.providerLogo && this.file == null) return this.providerLogo;
      return this.logo;
    },
    getProviderDui() {
      const found = this.provider.documents?.find(
        (el) => el.DocumentType.name === "DUI"
      );
      if (found) return found.document_number;
      else return null;
    },
    getProviderNit() {
      const found = this.provider.documents?.find(
        (el) => el.DocumentType.name == "NIT"
      );
      if (found) return found.document_number;
      else return null;
    },
    getFullAddress() {
      if (this.provider.address) {
        return `${this.provider.address?.country}, ${this.provider.address?.department}, ${this.provider.address?.municipality}, ${this.provider.address?.address_detail}`;
      } else return null;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.editableFields.direccion.$dirty) return errors;
      !this.$v.editableFields.direccion.required &&
        errors.push("La dirección es requerida");
      !this.$v.editableFields.direccion.address &&
        errors.push(
          "La dirección es inválida, caracteres especiales no permitidos"
        );
      !this.$v.editableFields.direccion.maxLength &&
        errors.push("La dirección no debe exceder los 100 caracteres");
      return errors;
    },
    NombreProveedorErrors() {
      const errors = [];
      if (!this.$v.editableFields.nombre.$dirty) return errors;
      !this.$v.editableFields.nombre.alpha &&
        errors.push("Nombre de proveedor solo admite caracteres alfanuméricos");
      !this.$v.editableFields.nombre.maxLength &&
        errors.push(
          "El nombre de proveedor no debe exceder los 100 caracteres"
        );
      !this.$v.editableFields.nombre.required &&
        errors.push("El nombre de proveedor es requerido");

      return errors;
    },
    NombreComercialErrors() {
      const errors = [];
      if (!this.$v.editableFields.nombre_comercial.$dirty) return errors;
      !this.$v.editableFields.nombre_comercial.alpha &&
        errors.push("Nombre comercial solo admite caracteres alfanuméricos");
      !this.$v.editableFields.nombre_comercial.maxLength &&
        errors.push("El nombre comercial no debe exceder los 100 caracteres");
      !this.$v.editableFields.nombre_comercial.required &&
        errors.push("El nombre comercial es requerido");

      return errors;
    },
    SitioWebErrors() {
      const errors = [];
      if (!this.$v.editableFields.sitio_web.$dirty) return errors;
      !this.$v.editableFields.sitio_web.url &&
        errors.push("El sitio web es inválido");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.editableFields.telefono.$dirty) return errors;
      !this.$v.editableFields.telefono.phoneValid &&
        errors.push("Escriba un número de teléfono válido");
      return errors;
    },
    paisErrors() {
      const errors = [];
      if (!this.$v.editableFields?.id_pais.$dirty) return errors;
      !this.$v.editableFields?.id_pais.required &&
        errors.push("El campo país es obligatorio");
      return errors;
    },
    departamentoErrors() {
      const errors = [];
      if (!this.$v.editableFields.id_departamento.$dirty) return errors;
      !this.$v.editableFields.id_departamento.required &&
        errors.push("El campo departamento es obligatorio");
      return errors;
    },
    municipioErrors() {
      const errors = [];
      if (!this.$v.editableFields.id_municipio.$dirty) return errors;
      !this.$v.editableFields.id_municipio.required &&
        errors.push("El campo municipio es obligatorio");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editableFields.email.$dirty) return errors;
      !this.$v.editableFields.email.required &&
        errors.push("El campo correo electrónico es obligatorio");
      !this.$v.editableFields.email.email &&
        errors.push("El correo electrónico es inválido");
      return errors;
    },
    clasificacionEmpresaErrors() {
      const errors = [];
      if (!this.$v.editableFields.id_clasificacion_empresa.$dirty)
        return errors;
      !this.$v.editableFields.id_clasificacion_empresa.required &&
        errors.push("El campo clasificación empresarial es obligatorio");
      return errors;
    },
  },
  methods: {
    ...mapMutations("catalogoEnLinea", ["clearInCartRequest"]),
    ...mapMutations("cmSolicitudesConObservacion", ["clearRequestsToModify"]),
    ...mapMutations("utils", ["setSideBar", "setMenu"]),
    ...mapMutations(["setToken", "setSelectedUnidad", "setUnidades"]),
    ...mapActions("proveedores", [
      "getProvider",
      "uploadProviderLogo",
      "getProviderLogo",
    ]),
    ...mapActions(["setInstitucionesUsuario"]),
    ...mapActions("registroProveedor", [
      "obtener_clasificaciones_empresariales",
    ]),
    limpiar() {
      this.file = null;
      this.logo = null;
    },
    getDocumentName(data) {
      if (data !== undefined && data.length > 0) {
        return data[0]?.TipoDocumento?.nombre;
      }
      return "";
    },
    getDocumentNumber(data) {
      if (data !== undefined && data.length > 0) {
        return data[0]?.numero_documento;
      }
      return "";
    },
    getAddress(data) {
      let address = `${data?.pais}${
        data?.departamento ? ", " + data?.departamento : ""
      }${
        data?.municipio ? ", " + this.getMunicipioName(data?.municipio) : ""
      }, ${data?.detalle_direccion}`;
      return address;
    },
    getMunicipioName(data) {
      let name = data?.split(" ");
      name?.pop();
      return name?.join(" ");
    },
    obtenerImagen(e) {
      this.cargarLogo((this.file = e.target.files[0]));
    },
    cargarLogo(f) {
      if (f !== null) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.logo = e.target.result;
        };
        reader.readAsDataURL(f);
        this.uploadProviderLogo({ id: this.provider.id, image: this.file });
      }
    },
    openAddList(tipo) {
      this.$router.push("/perfil_proveedor/agregar/" + tipo);
    },
    redirect(i) {
      switch (i) {
        case 1:
          this.$router.push({ name: "agregarDocumentacion" });
          break;
        case 2:
          this.$router.push({ name: "agregarAccionista" });
          break;
        case 3:
          this.$router.push({ name: "agregarContacto" });
          break;

        default:
          break;
      }
    },
    async getPaises() {
      const response = await this.services.Paises.getListaPaises();
      this.paises = response?.data;
    },
    async getDepartamentos() {
      this.editableFields.area = this.getCode(this.editableFields?.id_pais);
      if (this.editableFields?.id_pais != null) {
        const response = await this.services.Departamentos.getDepartamentosList(
          { id_pais: this.editableFields?.id_pais }
        );
        this.departamentos = response?.data;
      }
    },
    async getMunicipios() {
      if (this.editableFields.id_departamento != null) {
        const response = await this.services.Municipios.getMunicipiosList({
          id_departamento: this.editableFields.id_departamento,
        });
        this.municipios = response?.data;
      }
    },
    getCode(pais) {
      const code = this.paises.find((item) => item.id === pais);
      return code?.codigo_area;
    },
    clearAddressFields() {
      if (this.editableFields?.id_pais !== 68) {
        this.editableFields.id_departamento = null;
        this.editableFields.id_municipio = null;
        this.mask = null;
      } else {
        this.mask = "####-####";
        this.getDepartamentos();
      }
      this.editableFields.area = this.getCode(this.editableFields?.id_pais);
    },
    async editProfileData() {
      this.$v.editableFields.$touch();
      if (!this.$v.editableFields.$invalid) {
        const snapshot = {
          nombre: this.provider?.nombre,
          nombre_comercial: this.provider?.nombre_comercial,
          sitio_web: this.provider?.sitio_web,
          telefono: this.provider?.telefono,
          area: this.provider?.area,
          direccion: this.provider?.direccion?.detalle_direccion,
          id_pais: this.provider?.direccion?.id_pais,
          id_departamento: this.provider?.direccion?.id_departamento,
          id_municipio: this.provider?.direccion?.id_municipio,
          email: this.provider?.email,
          id_clasificacion_empresa: this.provider?.id_clasificacion_empresa,
        };

        const fields = Object.keys(this.editableFields).reduce((acc, key) => {
          if (snapshot[key] !== this.editableFields[key]) {
            acc[key] = this.editableFields[key];
          }
          return acc;
        }, {});

        if (Object.keys(fields).length === 0) {
          this.temporalAlert({
            show: true,
            type: "info",
            message: "No se han realizado cambios",
          });

          return;
        } else {
          let response = await this.services.Proveedores.updateProviderInfo(
            fields
          );

          if (response.status == 200) {
            if (fields.email) {
              this.temporalAlert({
                message:
                  "Se deslogueará para que pueda ingresar con su nueva dirección de correo",
                show: true,
                type: "info",
              });

              setTimeout(() => {
                this.setMenu([]);
                this.setToken(null);
                this.setSelectedUnidad({});
                this.setInstitucionesUsuario([]);
                this.setUnidades([]);
                this.clearInCartRequest();
                this.clearRequestsToModify();
                const tokenID = localStorage.getItem("tokenID");
                this.services.Auth.logout(tokenID);
                Observable.emit("logout", { "proveedor-logout": true });
                localStorage.clear();
                this.$router
                  .push({ name: "identidad-digital" })
                  .catch((e) => {});
              }, 3000);
            } else {
              this.editFields = false;
              this.getProvider();
              this.temporalAlert({
                show: true,
                message: "Su información ha sido actualizada",
                type: "success",
              });
            }
          }
        }
      }
    },
    async getProfilePercentage() {
      let response = await this.services.Proveedores.getProviderPercentage();

      if (response.status == 200) {
        this.progresoPerfil = response?.data.progreso;
        this.perfilCompleto = response?.data.perfil_completo;
      }
    },
    async solveObservations() {
      const { status } = await this.services.Proveedores.revisionProveedor(
        this.provider.id
      );

      if (status == 200 || status == 204) {
        location.reload();
      }
    },
    replaceFun(str) {
      if (str) {
        return str.replace(/<[^>]*>/g, "");
      } else {
        return "";
      }
    },
  },
  async created() {
    this.proveedor.url_image = "";
    if (Number(this.$route.params.idProveedor)) {
      await this.getProvider(Number(this.$route.params.idProveedor));
    } else {
      await this.getProvider();
    }

    this.getProviderLogo(this.provider.id);
    Object.keys(this.editableFields).forEach((key) => {
      this.editableFields[key] = this.provider[key];
      if (
        key == "id_pais" ||
        key == "id_departamento" ||
        key == "id_municipio"
      ) {
        this.editableFields[key] = this.provider.direccion[key];
      }
      if (key == "direccion")
        this.editableFields[key] = this.provider.direccion.detalle_direccion;
    });
    if (this.editableFields?.id_pais == 68) {
      this.mask = "####-####";
    }
    await this.getPaises();
    await this.getDepartamentos();
    await this.getMunicipios();
    if (this.$route.params.idProveedor == null) {
      await this.getProfilePercentage();
    }
    await this.obtener_clasificaciones_empresariales();
  },
};
</script>

<style scoped>
.image-input {
  max-width: 500px;
}

.text-title {
  font-size: 12px;
  margin: 0;
}

.text-info {
  font-size: 16px;
  margin: 0;
}

.espace {
  padding-bottom: 10px;
}

.input-space {
  padding-top: 50px;
}
</style>
